<template>
  <TableRow>
    <TableCell>
      <div class="holiday-nameanddate">
        <span class="holiday-name">
          {{ holiday }}
        </span>
        {{ date }}
      </div>
    </TableCell>
    <TableCell class="holiday-details" align="right">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="schedule" />
    </TableCell>
  </TableRow>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import TableCell from "~/components/Table/TableCell"
  import TableRow from "~/components/Table/TableRow"
  export default {
    components: {
      TableCell,
      TableRow,
    },
    mixins: [AlchemyElement],
    computed: {
      holiday() {
        return this.getValue("holiday")
      },
      date() {
        return this.getValue("dates")
      },
      schedule() {
        return this.getRichtext("shipping_schedule")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .holiday-name {
    font-weight: bold;
  }

  .holiday-nameanddate {
    @include viewport("mini", "sm") {
      display: flex;
      flex-direction: column;
    }
  }
</style>

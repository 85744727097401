<template>
  <Container>
    <Headline :level="headlineLevel">
      {{ headline }}
    </Headline>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell is-head>Name/Date</TableCell>
          <TableCell is-head align="right"> Details </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <HolidayRow
          v-for="element in element.nestedElements"
          :key="element.id"
          :element="element"
        />
      </TableBody>
    </Table>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="shipping-disclaimer" v-html="disclaimer" />
  </Container>
</template>

<script>
  import HolidayRow from "./holiday_row"
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import TableHead from "~/components/Table/TableHead"
  import TableBody from "~/components/Table/TableBody"
  import TableCell from "~/components/Table/TableCell"
  import TableRow from "~/components/Table/TableRow"

  export default {
    components: {
      TableHead,
      TableBody,
      TableCell,
      TableRow,
      HolidayRow,
    },
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline") || this.getValue("title")
      },
      headlineLevel() {
        return this.getIngredient("headline")?.level || 3
      },
      disclaimer() {
        return this.getRichtext("shipping_disclaimer")
      },
    },
  }
</script>

<style lang="scss">
  /* We can't use scoped here as we use v-html */

  .shipping-disclaimer {
    @apply text-sm leading-normal;
    margin-bottom: $space-m;
  }
</style>
